.support-section {
  padding: 32px 0;
}
.support-section .itembox .icon-wrap {
  margin-bottom: 15px;
  /* margin-top: 7px; */
}

.support-section .icon-lg {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 42px;
}
.support-section .icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}

@import "../../../../../assets/theme/variable";
@import "../../../../../assets/theme/fonts";

.ScannerAppSettingSection {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
  gap: 40px;
  border: 1px solid $lightGray;
  margin-top: 55px;

  .ScannerAppSettingOptionsBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // margin-top: 40px;

    .ScannerAppSettingOptionTitle {
      font-weight: 600;
      font-family: $mont;
    }

    .ScannerAppSettingOptionDescBox {
      display: flex;
      align-items: center;
      gap: 5px;

      .ScannerAppSettingOptionDescIcon {
        font-size: 13px;
      }

      .ScannerAppSettingOptionDesc {
        font-family: $mont;
        font-size: 12px;
      }
    }
  }

  .ScannerAppSettingSaveBtn {
    background-color: $brownDark;
    padding: 10px 20px;
    border-radius: 30px;
    color: $white;
    font-family: $mont;
    text-transform: uppercase;
    font-weight: 600;
    word-spacing: 2px;
    border: none;
    outline: none;
  }
}

.ScannerAppSettingRadioBtn:where(
    .css-dev-only-do-not-override-amq5gd
  ).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: $brownDark;
  background-color: $brownDark;
}

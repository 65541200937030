.cart .card-header h6 {
  font-weight: 600;
}

.cart .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.cart .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f1f3f7;
  border-bottom: 1px solid #dae2e6;
}
.cart .text-muted,
.cart .table th {
  color: #6c757d !important;
}
.cart .text-muted th svg {
  font-size: 15px;
}
.cart .media {
  display: flex;
  align-items: flex-start;
}
.media .img-wrap {
  margin-right: 1rem;
}
.cart .card .img-wrap {
  overflow: hidden;
}
.cart .img-wrap {
  text-align: center;
  display: block;
}
.cart .img-sm {
  width: 80px;
  height: 80px;
}
.cart .table td {
  color: #212529 !important;
}
.cart .dlist-inline dd,
.cart .dlist-inline dt {
  display: inline-block;
}
.cart [class*="dlist-"] {
  margin-bottom: 5px;
}

.cart .price {
  color: #1a1a1d;
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
  display: block;
}
.cart .remove svg {
  font-size: 14px;
}
.cart .footbtn .btn {
  margin-right: 10px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.625rem 1.25rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.cart .footbtn:last-child {
  border: none;
}

.cart .sticky-top {
  position: sticky;
  top: 75px;
  z-index: 1020;
}
.cart .dlist-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart .dlist-align dd {
  margin-bottom: 0;
}

.venues-detail-section {
  padding-top: 32px;
}
.venues-detail-section .card-body {
  padding: 1.25rem;
}
.venues-detail-section .dlist-border > dt {
  width: 200px;
  margin-bottom: 0;
}
.venues-detail-section .dlist-align dt {
  width: 130px;
  float: left;
  word-wrap: break-word;
}
.venues-detail-section .dlist-border > dd {
  margin-left: 230px;
  margin-bottom: 0;
  border-left: 1px solid #f1f3f7;
  padding-bottom: 50px;
  padding-left: 50px;
}
.clearfix {
  clear: both;
}

@media (max-width: 991px) {
  .venues-detail-section .dlist-border > dt {
    float: none;
    margin-bottom: 15px;
  }
  .venues-detail-section .dlist-border > dd {
    margin-left: 0;
    padding-left: 0;
    border: none;
  }
}

.comment {
  border-bottom: 1px solid #e0e0e0 !important;
}
.venues-detail-section .comment span {
  font-weight: 700;
  font-size: 14px;
}
.venues-detail-section .comment label {
  font-size: 14px;
  color: #4b4f56;
}
.venues-detail-section .comment select {
  padding: 4px;
  color: #4b4f56;
  background-color: #f1f3f7;
  border: 1px solid #e0e0e0;
}
.venues-detail-section .comment select:focus-visible {
  outline: 0;
}
.venues-detail-section .comment-form textarea {
  font-size: 14px;
  padding: 12px;
  font-weight: 500;
}
.comment-form .btn {
  /* border-radius: 2px; */
  /* background-color: #9cb4d8; */
  /* border-color: #9cb4d8; */
  /* font-size: 14px; */
  /* font-weight: 700; */
  float: right;
}

@import "../../assets/theme/variable";

.card-banner {
    display: flex;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.25rem;
    background-position: 50%;
    background-repeat: no-repeat;
}
.card-banner, .card-banner .card-body {
    position: relative;
    background-size: cover;
}

// .zoom-in, .zoom-wrap:hover .zoom-in {
//     transition: transform .3s ease;
// }
.img-lazy-load {
    transition: opacity .3s ease-in-out;
    max-width: 100%;
    opacity: .5;
}
.img-lazy-load.b-loaded {
    opacity: 1;
}
.card-banner, .card-banner .card-body {
    position: relative;
    background-size: cover;
}
.card-banner .card-body {
    z-index: 10;
}
.card-banner .text-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 30px 20px 7px;
    border-radius: 0 0 0.25rem 0.25rem;
    background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.8));
    color: #fff;
}

.zoom-in:hover{
    transition: transform .3s ease-in-out;
    transform: scale(1.1);
}


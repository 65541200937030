.count .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}
.count .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0;
  width: 2em;
  text-align: center;
  line-height: 1;
  transition: all 0.1s linear;
}
.count .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0;
}
.count .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px 0;
  bottom: 0;
}
.count .input-group-btn-vertical > .btn:hover {
  background-color: #212529;
  border-color: #212529;
}
.count .input-group-btn-vertical .bootstrap-touchspin-up:hover {
  transform: translateY(-1px);
}
.count .input-group-btn-vertical .bootstrap-touchspin-down:hover {
  transform: translateY(1px);
}

.review {
  padding: 1rem 1.2rem;
  display: block;
  background: #fff;
  border-radius: 0.2rem;
}
.review .img-100-100,
.re_ent_img {
  max-height: 100px;
  max-width: 100px;
  margin-right: 5px;
}
.review .rating-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
}
.review .rating-stars {
  display: flex;
  gap: 2px;
}
.review .rating-stars li svg {
  font-size: 16px;
}
.review .font-weight-bold {
  font-weight: 700 !important;
}
.review.add {
  padding: 0;
  display: block;
  background: transparent;
  border-radius: 0;
}

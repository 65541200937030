.social-share-f-button {
  background-color: #0965fe;
}
.social-share-t-button {
  background-color: #404040;
}
.social-share-l-button {
  background-color: #0077b5;
}

.social-share-f-button,
.social-share-t-button,
.social-share-l-button {
  color: white;
  border-radius: 3px;
}
.social-share-f-button button,
.social-share-t-button button,
.social-share-l-button button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 2px 5px !important;
}

.social-share-f-button svg,
.social-share-t-button svg,
.social-share-l-button svg {
  font-size: 17px;
}
.social-share-f-button button span,
.social-share-t-button button span,
.social-share-l-button button span {
  font-size: 11px;
  font-weight: 600;
}

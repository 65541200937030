* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Open Sans, Cairo, sans-serif;
  font-size: 0.805rem;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
}
.fw-600 {
  font-weight: 600;
}
.h1,
h1 {
  font-size: 2.0125rem;
}
.h5,
h5 {
  font-size: 1.00625rem;
}
.h4,
h4 {
  font-size: 1.2075rem;
}
.h6,
h6 {
  font-size: 0.805rem;
}
a {
  color: #1a1a1d;
  text-decoration: none;
  transition: all 0.25s ease;
}
a:hover {
  color: #1a1a1d;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg-gray {
  background-color: #f1f3f7;
}
.text-muted {
  color: #6c757d !important;
}

.badge {
  padding: 0.5em;
  font-size: 85%;
  font-weight: 400;
  border-radius: 0;
}
.badge-info {
  color: #fff;
  background-color: #31708f;
}
.badge-success {
  color: #fff;
  background-color: #0ba;
}
.badge-danger {
  color: #fff;
  background-color: #e95b35;
}
.badge-warning {
  color: #212529;
  background-color: #f3bc65;
}
.organizer__control.css-13cymwt-control {
  padding: 0.225rem 0rem;
  border: 1px solid #f1f3f7;
}
.organizer__control.css-13cymwt-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #565660;
  outline: 0;
  box-shadow: none;
}
/* admin table */
.table .tb-icon {
  font-size: 30px;
}
.avatar {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  display: inline-block;
  background: #ced4da no-repeat 50% / cover;
  position: relative;
  text-align: center;
  color: #6c757d;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avatar {
  background-color: #f1f3f7 !important;
  vertical-align: middle;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: 50% !important;
}
.flag {
  width: 1.46667rem;
  height: 1.15rem;
}

.alert-warning {
  background-color: #f3bc65;
}
.alert {
  color: #fff;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0.75rem 1.25rem;
}
.alert svg {
  font-size: 17px;
}
.alert-info {
  background-color: #31708f;
}
.alert-danger {
  background-color: #e95b35;
}
.text-success {
  color: #0ba !important;
}
span.organizer-profile-logo {
  height: 200px;
  width: 200px;
  background-color: #fff !important;
  background-size: 130px 130px !important;
  border: 1px solid #e7e8eb;
}
:where(.css-dev-only-do-not-override-amq5gd).ant-tooltip .ant-tooltip-inner {
  /* width: 200px !important; */
  /* text-align: center !important; */
  padding: 7px 9px !important;
}

/* category */
.card-banner-c,
.card-banner-c .card-body {
  position: relative;
  background-size: cover;
}
.card-banner-c {
  display: flex;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.25rem;
  background-position: 50%;
  background-repeat: no-repeat;
}
.card-banner-c .text-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 30px 20px 7px;
  border-radius: 0 0 0.25rem 0.25rem;
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.8));
  color: #fff;
}
.card-banner-c .card-body {
  transform: scale(1);
  transition: all 0.2s ease;
}
.card-banner-c .card-body:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.ant-radio-wrapper:hover .ant-radio-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}
.ant-radio-wrapper .ant-radio-checked::after {
  border: none !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  border-color: #1a1a1d !important;
  background-color: #1a1a1d !important;
}

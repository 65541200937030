.footer {
  background-color: #f1f3f7;
  border-top: 1px solid #e0e0e0 !important;
}
.footer-top {
  padding-top: 32px;
}
.title {
  font-weight: 600;
}
.footer-top ul a {
  color: #777;
}
.footer-top ul a:hover {
  color: #000;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover {
  color: #121416 !important;
}
.text-gray {
  color: #f1f3f7 !important;
}
.footer .btn-group .btn {
  border: 0;
  padding: 0.625rem 1.25rem;
  color: white;
  transform: translateY(0px);
  transition: all 0.15s ease;
}
.footer .btn-group .btn > svg {
  font-size: 20px;
}

.footer .btn-group .btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.footer .btn-facebook,
.footer .btn-facebook:hover {
  background-color: #405d9d;
  border-radius: 50px;
}
.footer .btn-instagram,
.footer .btn-instagram:hover {
  background-color: #e52d27;
}
.footer .btn-youtube,
.footer .btn-youtube:hover {
  background-color: #c8046c;
}
.footer .btn-twitter {
  background-color: #42aeec;
  border-radius: 50px;
}
.footer .btn-twitter:hover {
  background-color: #42aeec;
}
.title > .glob {
  font-size: 20px;
}

.dropdown .languagedropdownMenu {
  position: absolute;
  transform: translate(-98px, -203px);
  top: 0;
  left: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 40, 100, 0.12);
  background-color: white;
  z-index: 999;
  width: 10rem;
  opacity: 0;
  visibility: hidden;
}
.dropdown .languagedropdownMenu > ul {
  padding: 10px 0;
}
.dropdown .languagedropdownMenu > ul > li {
  padding: 8px 0 8px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.language {
  cursor: pointer;
}
.dropdown .language:hover + .languagedropdownMenu,
.dropdown .languagedropdownMenu:hover {
  opacity: 1;
  visibility: visible;
}
.languagedropdownMenu > ul > li:hover {
  color: #000;
  background-color: #f1f3f7;
}

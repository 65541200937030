$primary: #ffffff;
$primaryDark: #818cde;
$silver: #f9f9f9;
// $primary: #6EC1E4;
$secondary: #54595f;
$secondaryDark: #888888;
$black: #000000;
$text: #b2bac1;
$accent: #61ce70;
$card: #f8f9fb;
$white: #ffffff;
$icons: #333333;
$border: #f1f3f7;
$lightGray: #efefef;
$disabled: #f8f9fb;
$input: #bbc2ca;
$brownDark: #1a1a1d;
$darkBrownSecondary: #343a40;
$icons: #444444;
$facebook: #1876f3;
$linkedIn: #1d9bf0;
$lightBlue: #31708fff;
$yellow: #f3bc65ff;
$price: #5995c1;
$green: #01bbaaff;

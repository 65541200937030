.sortedbydate form label {
  font-weight: 600;
  padding-right: 10px;
}
.sortedbydate form select {
  border: 0;
  padding: 12px 6px;
}
.venues .card .img-wrap {
  overflow: hidden;
}
.venues .card-hover:hover {
  box-shadow: 0 4px 15px hsla(0, 0%, 60%, 0.3);
  transition: 0.5s;
}
.venues .img-wrap {
  text-align: center;
  display: block;
}
.venues .img-wrap img {
  max-width: 100%;
  max-height: 100%;
}
.venues h4 a {
  color: #1a1a1d;
}
.venues [class*="dlist-"] {
  margin-bottom: 5px;
}
.venues .dlist-align dt {
  width: 130px;
}
.venues .dlist-align dt {
  width: 90px;
  float: left;
  word-wrap: break-word;
}
.venues dt {
  margin-bottom: 0.25rem;
}
.venues .border-left {
  border-left: 1px solid #dee2e6 !important;
}
.venues .btn {
  color: white;
  background-color: black;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.625rem 1.25rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transform: translateY(0);
}
.venues .btn:hover {
  background-color: black;
  transform: translateY(-2px);
}
.ant-pagination {
  text-align: center;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .venues .center-lg-y {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
}

.contact a {
  color: #1a1a1d;
}
.contact .card {
  padding: 1rem 1.2rem;
}
.contact .itemside {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.contact figure {
  margin-bottom: 0;
}
.contact .round {
  border-radius: 200px;
}

.contact .icon-md {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 32px;
}
.contact .icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}

.contact .bg-info {
  background-color: #31708f !important;
}
.contact .bg-info svg {
  color: white;
}
.contact .itemside .text-wrap {
  padding-left: 15px;
  padding-right: 7px;
}
.contact .itemside .text-wrap h6 {
  font-weight: 500;
  font-size: 0.805rem;
}
.contact .contact-form .form-label {
  font-weight: 600;
}
.contact .contact-form .form-label span {
  color: red;
}
/* .contact .card:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: none;
} */

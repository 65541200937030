.OraganizerDashboardSection {
  padding: 20px;
  .OrganizerDashboardEventsCardSection {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }

  .OrganizerDashboardTableSection {
    padding: 10px;
    margin-top: 10px;
  }
}

.sign-title {
  font-weight: 400;
}
.signin-section {
  padding-top: 32px;
}
.btn-block {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
}
.btn-block,
.btn-tags {
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  /* vertical-align: middle; */
  white-space: nowrap;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.divider-text {
  position: relative;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.divider-text span {
  background-color: #fff;
  padding: 7px;
  font-size: 12px;
  position: relative;
  z-index: 2;
}
.divider-text:after {
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #ddd;
  top: 55%;
  left: 0;
  z-index: 1;
}

.signform .form-control:focus,
.react-datepicker-wrapper .react-datepicker__input-container input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #565660;
  outline: 0;
  box-shadow: none;
}
.signform .input-group-text {
  border: none;
  border-radius: 0;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  color: #495057;
  background-color: #e9ecef;
}
.signform .input-group-text svg {
  font-size: 13px;
}
.signform .form-control,
.react-datepicker-wrapper .react-datepicker__input-container input {
  font-size: 0.805rem;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #8898aa;
  border: 1px solid #f1f3f7;
  border-radius: 0.25rem;
  background-clip: padding-box;
  background-color: #f1f3f7;
}
.wbtn {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.signform .form-control::placeholder {
  color: #8898aa;
}

.signform .form-check-label {
  font-weight: 600;
}
.btn {
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
  text-transform: uppercase;
  transform: translateY(0);
  transition: all 0.2s linear;
}
.btn-sm {
  font-size: 0.675rem;
  padding: 0.225rem 1.5rem;
}
.btn:hover,
.otherbtn:hover {
  transform: translateY(-2px);
  background-color: #212529;
}
.wbtn:hover {
  transform: translateY(0);
  background-color: transparent;
}
.guest-user-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  font-weight: bold !important;
}
.signform .form-label.dashboard {
  font-size: 0.805rem;
}
.signform .form-control.is-invalid {
  border: 1px solid rgba(220, 53, 69);
}
.spinIcon {
  animation: spinIcon 1.3s linear infinite; /* 1s duration, linear easing, infinite loop */
}
.btn.signin:hover,
.btn.signup:hover,
.btn-tags:hover {
  background-color: transparent;
}

.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
}

.kTqvuD {
  width: 100% !important;
  font-size: 0.805rem !important;
  height: calc(2.75rem + 2px) !important;
  padding: 0.625rem 0.75rem !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
  color: #8898aa !important;
  border: 1px solid #f1f3f7 !important;
  border-radius: 0.25rem !important;
  background-clip: padding-box !important;
  background-color: #f1f3f7 !important;
}

@keyframes spinIcon {
  from {
    transform: rotate(0deg); /* Start rotation from 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate to 360 degrees */
  }
}

.organizer-event form label {
  font-weight: 600;
  padding-right: 10px;
}
.organizer-event form select {
  border: 0;
  padding: 12px 6px;
}

.organizer-event.box {
  padding: 1rem 1.2rem;
  display: block;
  border-radius: 0.2rem;
}
.organizer-event .btn {
  background-color: #1a1a1d;
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.625rem 1.25rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.organizer-event .btn:hover {
  background-color: #1a1a1d;
  transform: translateY(-2px);
}

.organizer-event .table td,
.organizer-event .table th {
  color: #212529;
  padding: 0.75rem;
  vertical-align: middle;
}
.organizer-event .img-50-50 {
  max-height: 70px;
  max-width: 70px;
}
.organizer-event .progress-xs,
.progress-xs .progress-bar {
  height: 0.25rem;
}
.table > tbody tr:last-child {
  border-color: transparent;
}
.table-hover tbody tr:hover td {
  background-color: #f1f3f7;
}
.organizer-event .table > :not(caption) > * > * {
  box-shadow: none;
  /* background-color: #f1f3f7; */
}

.organizer-event .chart-circle {
  display: block;
  height: 8rem;
  width: 8rem;
  position: relative;
}
.organizer-event .chart-circle-xs {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 0.8rem;
}
.organizer-event .chart-circle-value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1;
}
.organizer-event .chart-circle canvas {
  border: 1px solid gainsboro;
  border-radius: 50%;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-amq5gd).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-amq5gd).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title,
:where(.css-dev-only-do-not-override-amq5gd).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title {
  padding: 10px 33px;
}

.signform .tags-array .wrap {
  overflow: hidden;
  color: #8898aa;
  border: 1px solid #f1f3f7;
  border-radius: 0.25rem;
  cursor: text;
  background-color: #f1f3f7;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  padding: 0.625rem 0.75rem;
  font-size: 0.805rem;
}

@import "../../../../assets/theme/variable";
@import "../../../../assets/theme/fonts";

.DashboardHeaderSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .DashboardHeaderTitleBox {
    display: flex;
    align-items: center;
    gap: 4px;

    .DashboardHeaderIcon {
      font-size: 21px;
    }
    .DashboardHeaderTitle {
      font-size: 21px;
      font-weight: 600;
      font-family: $open-sans;
    }
  }

  .MangeEventBox {
    display: flex;
    align-items: center;
    gap: 5px;

    .ManageEventIcon {
      font-size: 16px;
    }
  }
}

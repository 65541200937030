@import "../../../../assets/theme/variable";
@import "../../../../assets/theme/fonts";

.DashboardCard {
  background-color: $brownDark;
  width: 227px;
  height: 100px;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .DashboardCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .DashboardCartContentDate {
      color: $white;
      font-size: 18px;
    }

    .DashboardCartContentText {
      color: $white;
      font-size: 18px;
    }
  }

  .DashboardCardIcon {
    top: 5px;
    right: 10px;
    position: absolute;
    color: $secondaryDark;
    font-size: 25px;
  }
}

.BlurryHeaderContainer {
  width: 100vw;
  height: 75vh;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    filter: blur(35px);
  }

  .imageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .BlurryHeaderImage {
      width: auto;
      height: auto;
      object-fit: contain;
      z-index: 100;
    }
  }
}

@media screen and (max-width: 1001px) {
  .BlurryHeaderImage {
    width: 700px !important;
  }
}

@media screen and (max-width: 720px) {
  .BlurryHeaderImage {
    width: 400px !important;
  }

  .BlurryHeaderContainer {
    width: 100vw;
    height: 50vh;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
}

@media screen and (max-width: 720px) {
  .BlurryHeaderImage {
    width: 300px !important;
    height: 280px;
  }

  .BlurryHeaderContainer {
    width: 100vw;
    height: 40vh;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
}

@import "../../../../../assets/theme/variable";
@import "../../../../../assets/theme/fonts";

.OrganizerProfileSection {
  padding: 20px 30px;
  margin-top: 50px;
  border: 1px solid $lightGray;
  border-radius: 4px;
  .OrganizerProfilePreviewProfileBox {
    display: flex;
    width: 100%;
    background-color: $lightBlue;
    padding: 12px 16px;
    border-radius: 4px;
    align-items: center;
    gap: 15px;
    margin-top: 30px;

    .OrganizerProfilePreviewProfileBoxLink {
      color: $brownDark;

      .OrganizerProfilePreviewProfileBoxText {
        color: $white;
      }
    }

    .OrganizerProfilePreviewProfileBoxIcon {
      font-size: 18px;
      color: $white;
    }
  }
}

.OrganizerOptionDescBox {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0px;

  .OrganizerOptionDescIcon {
    font-size: 11px;
  }

  .OrganizerOptionDesc {
    font-size: 11px;
    font-family: $mont;
  }
}

.ProfileSettingOptionsContainer {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  gap: 15px;
  .ProfileSettingOptionsBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // margin-top: 40px;

    .ProfileSettingOptionTitle {
      font-weight: 600;
      font-family: $mont;
    }

    .ProfleSettingOptionDescBox {
      display: flex;
      align-items: center;
      gap: 5px;

      .ProfleSettingOptionDescIcon {
        font-size: 13px;
      }

      .ProfleSettingOptionDesc {
        font-family: $mont;
        font-size: 12px;
      }
    }
  }
}

.ProfileSettingRadioBtn:where(
    .css-dev-only-do-not-override-amq5gd
  ).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: $brownDark;
  background-color: $brownDark;
}
input[type="radio" i]:focus-visible {
  outline-offset: 0;
  box-shadow: none;
}
.ProfileSettingRadioBtn:where(.css-dev-only-do-not-override-amq5gd)[class^="ant-radio"] [class^="ant-radio"]:hover{
  // outline-offset: 0;
  border-color: red !important;
}

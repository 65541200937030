@import "../../../../../assets/theme/variable";
@import "../../../../../assets/theme/fonts";

.OrganizerPayoutMethodsSection {
  margin-top: 50px;

  .OrganizerPayoutMethodsTitleBox {
    padding: 10px;
    background-color: $border;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .OrganizerPayoutMethodsTitle {
      font-weight: 600;
      font-family: $mont;
    }
  }

  .OrganizerPayoutMethodsHeader {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    // background-color: aquamarine;
    gap: 400px;

    .OrganizerPayoutMethodsHeaderWrapper {
      display: flex;
      justify-content: space-between;
      gap: 250px;
      // background-color: red;
      .OrganizerPayoutMethodsHeaderText {
        font-weight: 600;
        font-family: $mont;
      }
    }

    .OrganizerPayoutMethodsHeaderText:nth-child(3) {
      font-size: 18px;
    }
  }

  .OrganizerPayoutMethodsContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

@media screen and (max-width: 1400px) {
  .OrganizerPayoutMethodsHeader {
    gap: 250px !important;

    .OrganizerPayoutMethodsHeaderWrapper {
      gap: 200px !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .OrganizerPayoutMethodsHeader {
    gap: 200px !important;

    .OrganizerPayoutMethodsHeaderWrapper {
      gap: 150px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .OrganizerPayoutMethodsHeader {
    gap: 150px !important;
    justify-content: center !important;

    .OrganizerPayoutMethodsHeaderWrapper {
      gap: 50px !important;
    }
  }
}

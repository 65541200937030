.help-center-hero-bg {
  background-image: linear-gradient(
      135deg,
      rgba(50, 50, 56, 0.8),
      rgba(2, 2, 2, 0.9)
    ),
    url(/public/images/hp.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-img,
.help-center-hero-bg {
  overflow: hidden;
  background-color: #ddd;
  background-position: 0 0, 50% 50%;
}
.padding-x-md {
  padding-right: 30px;
  padding-left: 30px;
}
.padding-y-lg {
  padding-top: 60px;
  padding-bottom: 50px;
}
.section-help-center .input-icon {
  position: relative;
}
.section-help-center .input-icon svg {
  position: absolute;
  color: #ccc;
  z-index: 9;
  left: 7px;
  top: 9px;
  width: 24px;
  text-align: center;
}
.section-help-center .input-icon svg + input,
.input-icon svg + select {
  padding-left: 36px;
}

.section-help-center .form-control,
.form-control:focus {
  box-shadow: none;
}
.section-help-center .form-control {
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #8898aa;
  font-size: 0.805rem;
  border: 1px solid #f1f3f7;
  border-radius: 0.25rem;
  background-clip: padding-box;
  background-color: #f1f3f7;
}
.section-help-center .form-control::placeholder {
  color: #8898aa;
}
.help-card {
  background-color: #f1f3f7;
}
.help-card .box {
  padding: 1rem 1.2rem;
  display: block;
  background: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 1px 3px rgba(51, 51, 51, 0.1);
}
.help-card .itembox.icon-wrap {
  margin-bottom: 15px;
}
.help-card .icon-lg {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 42px;
}
.help-card .icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
  align-items: center;
}
.help-card .icon-wrap,
.helpc .icon-wrap {
  text-align: center;
}
.help-card .rounded,
.helpc .rounded {
  border-radius: 0.25rem !important;
}
.help-card .round,
.helpc .round {
  border-radius: 200px;
}
.help-card .icon-md,
.helpc .icon-md {
  width: 60px;
  height: 60px;
  line-height: 50px;
  font-size: 32px;
}
.help-card .contactus .btn,
.helpc .contactus .btn {
  font-size: 0.875rem;
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.625rem 1.25rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transform: translateY(0);
  background-color: #343a40;
}
.help-card .contactus .btn:hover,
.helpc .contactus .btn:hover {
  background-color: #343a40;
  transform: translateY(-2px);
}

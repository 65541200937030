.card {
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}
.card .card-img-top.event-card {
  height: 170px;
}
.card .card-img-top.home-card-event {
  height: 220px;
}
.event-category {
  color: #7f818e;
  border-radius: 5px;
  background-color: #fff;
  right: -10px;
  padding: 0.625rem 1.25rem;
}
.event-date,
.event-category {
  position: absolute;
  top: 10px;
  box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
    0 2px 2px 0 rgba(40, 44, 53, 0.08);
  z-index: 3;
  font-size: 12px;
  opacity: 0.97;
}
.event-date {
  left: 10px;
}
.event-month {
  position: relative;
  padding: 5px 17px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.event-day {
  font-weight: 700;
  padding: 10px 17px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.card-body {
  position: relative;
}
.card .event-favorite {
  position: absolute;
  top: -10px;
  right: 5px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 50%;
}

.event-card:hover {
  box-shadow: 0 4px 15px hsla(0, 0%, 60%, 0.3);
  transition: 0.5s;
}
.event-card .price-wrap {
  float: right;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.event-card .price-new {
  margin-right: 5px;
}
.events .highlighted {
  background-color: #e6f7ff; /* Adjust the background color as needed */
}

@import "../../../../../assets/theme/variable";

// .Sidebar {
//   width: auto !important;
//   height: auto !important;
//   // overflow: auto;
// }




.ant-menu
  :where(.css-dev-only-do-not-override-pr0fja).ant-menu-light
  .ant-menu-item-selected,
.ant-menu-item-selected {
  background-color: $border !important;
}

.ant-menu
  :where(.css-dev-only-do-not-override-pr0fja).ant-menu-light
  .ant-menu-item-selected,
.ant-menu-item-selected {
  color: $brownDark !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
// 
.attendeeSidebar {
  padding: 32px 0;
}
.attendeeSidebar a div{
  display: flex;
  align-items: center;
}
.attendeeSidebar a div spna {
  display: flex;
  margin-right: 5px;
}

.attendeeSidebar .alert-warning {
  background-color: #f3bc65;
}
.attendeeSidebar .alert {
  color: #fff;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
  padding: 0.75rem 1.25rem;
}
.attendeeSidebar .alert svg{
  font-size: 17px;
}
.attendeeSidebar .alert-info {
  background-color: #31708f;
}
// li.ant-menu-item.sdgm{
//   color: red;
// }

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  // position: absolute;
  //   top: 0;
  //   z-index: 999999;
}

.Sidebar {
  overflow: auto;
  flex-grow: 1;
}

/* Media query for hiding/showing sidebar on small screens */
@media (max-width: 768px) {
  // .collapsed {
  //   .Sidebar {
  //     display: none;
  //   }
  // }

  // .ant-menu-inline-collapsed {
  //   width: 80px !important;
  // }
  :where(.css-dev-only-do-not-override-amq5gd).ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: 0px !important;
}
  .Sidebar {
    padding: 0px !important;
}

}
.pricing-and-fees p {
  font-weight: bolder;
}
.pricing-and-fees .card {
  padding: 1rem 1.2rem;
}
.pricing-and-fees .card:hover,
.resetPassword .card:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: none;
}

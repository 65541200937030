@import "../../../../../../assets/theme/variable";
@import "../../../../../../assets/theme/fonts";
@import "../../../../../../assets/theme/component.scss";

.AdminHomepageSection {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .AdminHomepageSliderOptionSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-start;

    .AdminHomepageBtn {
      background-color: $brownDark;
      border: none;
      outline: none;
      color: $white;
      padding: 10px 15px;
      border-radius: 30px;
      margin-top: 20px;
      font-weight: 600;
      font-family: $mont;
    }

    .AdminHomepageSliderOptionTitle {
      font-weight: 600;
      font-size: 13px;
      font-family: $mont;
    }
  }
}

.AdminHomepageEvensTags:where(
    .css-dev-only-do-not-override-amq5gd
  ).ant-select-multiple
  .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
  background-color: $border;
}

.AdminHomepageEvensTags:where(
    .css-dev-only-do-not-override-amq5gd
  ).ant-select-multiple.ant-select-show-arrow
  .ant-select-selector,
:where(
    .css-dev-only-do-not-override-amq5gd
  ).ant-select-multiple.ant-select-allow-clear
  .ant-select-selector {
  padding-inline-end: 24px;
  background-color: $border;
  border: none !important;
  border-radius: 0px !important;
  padding: 10px;
}

@import "../../assets/theme/fonts";
@import "../../assets/theme/variable";

.Slider{
    width: 100%;
    height: 83vh;
    // position: relative;

    .SliderSlide{

        .Slide{
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
           
            .SliderContent{
                z-index: 1;
                position: absolute;
                bottom: 230px;
                left: 200px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .SliderTitle{
                    color:  $white;
                    font-size: 30px;
                    font-weight: 500;
                    font-family: $open-sans;
                }


                .SliderDetailsWrapper{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;

                    .SliderVenue{
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .SliderIcon{
                            color: $white;
                        }

                        .SliderAddress{
                            color:  $white;
                            font-weight: 500;
                            font-family: $open-sans;
                        }
                    }    
                }

                .SliderBTN{
                    width: 150px;
                    height: 44px;
                    border: none;
                    outline: none;
                    border-radius: 30px;
                    font-family: $open-sans;
                    font-weight: 600;
                    font-size: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;
                    cursor: pointer;

                    .SliderIcon{
                        font-size: 15px;
                    }
                }
            }
        }

       
    }

}


.SliderLeftBtn{
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 10000;
    color: $white;
    font-size: 50px;
    cursor: pointer;
}

.SLiderRightBtn{
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 10000;
    color: $white;
    cursor: pointer;
    font-size: 50px;
}

.swiper-button-next {
    color: $white !important;
}

.swiper-button-prev  {
    color: $white !important;
}
.swiper-button-next ,.swiper-button-prev {
    display: none !important;
}
.swiper-pagination-bullet{
    background-color:  $border !important;
    width: 15px !important;
    height: 15px !important;
}


@media screen and (max-width: 865px) {

   
    .SliderContent{
        bottom: 230px;
        left: 100px !important;
        gap: 20px;

        .SliderTitle{
            font-size: 30px;
        }


        .SliderDetailsWrapper{
            display: flex;
            flex-direction: column;
            gap: 2px;

            .SliderVenue{
                display: flex;
                gap: 10px;
            }    
        }

        .SliderBTN{
            width: 150px;
            height: 44px;
            border: none;
            outline: none;
            border-radius: 30px;
            font-family: $open-sans;
            font-weight: 600;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3px;
            cursor: pointer;

            .SliderIcon{
                font-size: 15px;
            }
        }
    }
  }


  @media screen and (max-width: 595px) {

   
    .SliderContent{
        bottom: 230px;
        left: 50px !important;
        gap: 10px;

        .SliderTitle{
            font-size: 25px !important;
        }


        .SliderDetailsWrapper{
            gap: 2px;

            .SliderAddress{
                width: 360px;
                font-size: 14px !important;
            }

            .SliderVenue{
                display: flex;

                gap: 10px;
            }    
        }

        .SliderBTN{
            width: 150px;
            height: 44px;
            border: none;
            outline: none;
            border-radius: 30px;
            font-family: $open-sans;
            font-weight: 600;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3px;
            cursor: pointer;

            .SliderIcon{
                font-size: 15px;
            }
        }
    }
  }


  @media screen and (max-width: 450px) {

   
    .SliderContent{
        bottom: 230px;
        left: 50px !important;
        gap: 10px;

        .SliderTitle{
            font-size: 20px !important;
        }


        .SliderDetailsWrapper{
            gap: 2px;

            .SliderAddress{
                width: 270px;
                font-size: 12px !important;
            }

            .SliderVenue{
                display: flex;
                
                gap: 10px;
            }    
        }

        .SliderBTN{
            width: 150px;
            height: 44px;


            .SliderIcon{
                font-size: 15px;
            }
        }
    }
  }




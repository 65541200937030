.blog .blog-post-card .card-body {
  padding: 0.5rem;
}
.blog p small,
.blog p small a {
  display: inline-flex;
  align-items: center;
}
.blog .blog-post-card h5 {
  font-size: 0.90625rem;
}

.blog-detail .box {
  padding: 1rem 1.2rem;
  display: block;
  background: #fff;
  border-radius: 0.2rem;
  /* box-shadow: 0 1px 3px rgba(51,51,51,.1); */
}
/* .blog-detail .box img {
  height: 750px;
} */
.blog-detail .title-section {
  padding-right: 15px;
  font-weight: 600;
}
.blog-detail p small,
.blog-detail p small a {
  display: inline-flex;
  align-items: center;
}
.blog-detail p span {
  background-color: rgb(85, 98, 113);
  color: rgb(123, 136, 152);
  font-family: "Mercury SSm A", "Mercury SSm B", Georgia, Times,
    "Times New Roman", "Microsoft YaHei New", "Microsoft Yahei", 微软雅黑, 宋体,
    SimSun, STXihei, 华文细黑, serif;
  font-size: 26px;
}
.blog-detail hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0 0 0 / 41%);
}
.blog-detail .btn {
  text-transform: uppercase;
  padding: 0.25rem 1.5rem;
  font-size: 0.66563rem !important;
  line-height: 1.5 !important;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

@import "../../../../assets/theme/variable";

.BrowseEventDetailsCard {
  width: 60%;
  flex: 1;
  border-right: 1px solid $lightGray;
  padding-right: 20px;

  .BrowseEventCardHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;

    .BrowseEventCardHeaderText {
      font-size: 31px;
      font-weight: 600;
    }

    .ReviewBox {
      display: flex;
      align-items: center;
      gap: 5px;

      .ReviewWrapper {
        display: flex;
        align-items: center;
        gap: 3px;
        .ReviewStarIcon {
          color: $input;
        }
      }

      .ReviewHeaderText {
        font-size: 14px;
      }
    }
  }

  .BrowseEventCardDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid $lightGray;
    padding-bottom: 60px;
    margin-top: 30px;
  }

  .BrowseEventCardCategoryDetails {
    display: flex;
    gap: 8px;
    flex-direction: column;
    border-bottom: 1px solid $lightGray;
    margin-top: 20px;
    padding: 15px 0px;

    .BrowseEventCardCategoryDetailsBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .BrowseEventCardCategoryTextDetailsWrapper {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .BrowseEventsSocialIconboxes {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid $lightGray;
    padding-bottom: 40px;
    margin-top: 20px;

    .BrowseEventsSocialIconbox {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 2px 6px;
      border-radius: 4px;

      .SocialIcons {
        color: $white;
      }
      .SocialText {
        color: $white;
        font-size: 14px;
      }
    }

    .BrowseEventsSocialIconbox:nth-child(1) {
      background-color: $facebook;
    }

    .BrowseEventsSocialIconbox:nth-child(2) {
      background-color: $brownDark;
    }

    .BrowseEventsSocialIconbox:nth-child(3) {
      background-color: $linkedIn;
    }
  }

  .BrowseEventsCardReviewsSection {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .BrowseEventsSocialIconReviewsHeader {
      // display: flex;

      .BrowseEventsCardReviewDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .BrowseEventsCardReviewsBox {
          display: flex;
          flex-direction: column;
          // gap: 6px;
          justify-content: flex-start;

          .ReviewCount {
            display: flex;
            gap: 5px;

            .BrowseEventsCardReviewDetailsReviewsCountText {
              font-size: 25px;
            }
          }

          .ReviewBox {
            display: flex;
            gap: 6px;
            align-items: center;

            .ReviewWrapper {
              display: flex;
              align-items: center;
              gap: 4px;

              .ReviewStarIcon {
                color: $input;
              }
            }
          }
        }
        .BrowseEventIcon{
          margin-right: 4px;
          font-size: 17px;
        }
        // .BrowseEventsCardReviewBtn {
        //   background-color: transparent;
        //   padding: 10px 20px;
        //   border-radius: 20px;
        //   border: 1px solid $brownDark;
        //   outline: none;
        //   display: flex;
        //   align-items: center;
        //   gap: 6px;

        //   .BrowseEventsCardReviewIcon {
        //     background-color: $white;
        //     font-size: 18px;
        //   }

        //   .BrowseEventsCardbtnText {
        //     font-weight: 600;
        //   }
        // }

        // .BrowseEventsCardReviewBtn:hover {
        //   background-color: $brownDark;
        //   transition: all 200ms ease-in;
        //   cursor: pointer;

        //   .BrowseEventsCardReviewIcon {
        //     color: $white;
        //     background-color: transparent;
        //     font-size: 18px;
        //   }

        //   .BrowseEventsCardbtnText {
        //     color: $white;
        //   }
        // }
      }
    }

    .BrowseEventsCardReviewDetailsReviewsLoaderWrapper {
      display: flex;
      margin-top: 30px;
      flex-direction: column;
      gap: 10px;
      .progress{
        border-radius: 0;
      }
    }
  }
  .list-icon.row.no-gutters li{
// padding: 8px auto !important;
margin: 2px 0;
  }
}

@media screen and (max-width: 990px) {
  .BrowseEventDetailsCard
    .BrowseEventsCardReviewsSection
    .BrowseEventsCardReviewDetailsReviewsLoaderWrapper
    .BrowseEventsCardReviewDetailsReviewsLoaderBox
    .BrowseEventsCardReviewDetailsReviewsLoader {
    width: 250px;
    height: 20px;
    background-color: #f1f3f7;
    border: 1px solid #bbc2ca;
  }
}

@media screen and (max-width: 770px) {
  .BrowseEventDetailsCard {
    width: 90%;
    flex: 1;
    border-right: 1px solid #efefef;
  }
}

@media screen and (max-width: 539px) {
  .BrowseEventsCardReviewDetails {
    display: flex;
    align-items: center;
    flex-direction: column !important;
    justify-content: space-between;
    gap: 15px;
  }

  .BrowseEventsCardReviewsBox {
    display: flex;
    flex-direction: column !important;
  }

  .ReviewBox {
    display: flex;
    flex-direction: column !important;
  }

  .BrowseEventCardCategoryText {
    font-size: 14px !important;
  }

  .BrowseEventCardCategoryTextDetailsText {
    font-size: 14px !important;
  }
}

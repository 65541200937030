@import "../../../../assets/theme/variable";
@import "../../../../assets/theme/fonts";

.BrowseEventSidebarSection {
  width: 32%;
  display: flex;
  justify-content: center;
  padding: 10px;

  .BrowseEventsSidebarContainer {
    width: 85%;

    .BrowseEventSidebarHeader {
      border-bottom: 1px solid $lightGray;
      padding-bottom: 20px;
      .BrowseEventSidebarHEaderTitle {
        font-weight: 700;
        margin-top: 20px;
      }

      .BrowseEventSidebarDateBox {
        display: flex;
        justify-content: center;
        align-items: center;

        .BrowseEventDateboxWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 6px;

          .BrowseEventDatesWrapper {
            display: flex;
            align-items: center;

            .BrowseEventDateboxDate:nth-child(1) {
              font-size: 51px;
            }

            .BrowseEventDateboxDate {
            }
          }

          .BrowseEventDateboxTime {
            font-weight: 700;
          }

          .BrowseEventDateboxWrappers {
            display: flex;
            align-items: center;
            gap: 6px;

            .BrowseEventDateboxCalender {
              font-size: 14px;
            }

            .BrowseEventDateboxCalenderText {
              font-size: 14px;
            }
          }
        }
      }
    }

    .BrowseEventVenueDetailsSection {
      margin-top: 30px;

      .BrowseEventVenueDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .BrowseEventVenueDetailsText {
          font-weight: 700;
        }

        .BrowseEventVenueDetailsBox {
          display: flex;
          align-items: center;

          .BrowseEventVenueDetailsBoxIcon {
            font-size: 14px;
            gap: 5px;
          }

          .BrowseEventVenueDetailsBoxText {
            font-size: 14px;
          }
        }
      }
    }

    .BrowseEventVenueDetailContent {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .BrowseEventVenueDetailContentTitle {
        font-size: 19px;
        margin-top: 5px;
      }

      .BrowseEventVenueDetailDesc {
        font-size: 13px;
      }
    }

    .BrowseEventVenueDetailIconsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      gap: 12px;

      .BrowseEventVenueDetailIcons {
        color: $input;
      }
    }

    .BrowseEventVenueDetailWrapperMap {
      margin-top: 15px;
      border-bottom: 1px solid $lightGray;
      padding-bottom: 50px;
    }

    .BrowseEventVenueDetailTicketBox {
      margin-top: 20px;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: flex-start;
      border-bottom: 1px solid $lightGray;
      padding-bottom: 30px;

      .BrowseEventVenueDetailTicketBoxTitle {
        font-size: 19px;
      }

      .BrowseEventVenueDetailTicketBtnWrapper {
        width: 100%;
        padding: 10px 12px;
        // height: 35px;
        // display: flex;
        background-color: $border;
        // justify-content: space-between;
        // align-items: center;
        margin-top: 10px;
      }
      // .BrowseEventsSidebarBtnSectionWrapper {
      //   width: 100%;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;

      //   align-items: center;
      //   margin-top: 20px;
      //   gap: 15px;

      //   .BrowseEventVenueDetailTicketBtn {
      //     background-color: $brownDark;
      //     border: none;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     gap: 5px;
      //     width: 100%;
      //     height: 44px;
      //     cursor: pointer !important;
      //     border-radius: 40px;
      //     transition: all 0.2s linear;
      //     border-radius: 40px;
      //     &:hover{
      //       transform: translateY(-2px);
      //       background-color: #212529;
      //     }

      //     .BrowseEventVenueDetailTicketBtnIcon {
      //       color: $white;
      //       font-size: 18px;
      //     }

      //     .BrowseEventVenueDetailTicketBtnText {
      //       color: $white;
      //       text-transform: uppercase;
      //       font-weight: 750;
      //     }
      //   }

      //   .BrowseEventVenueDetailTicketFVTBtn {
      //     background-color: $white;
      //     border: 1px solid $brownDark;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     gap: 5px;
      //     cursor: pointer;
      //     width: 100%;
      //     height: 44px;
      //     transform: translateY(0);
      //     transition: all 0.2s linear;
      //     border-radius: 40px;
      //     &:hover{
      //       transform: translateY(-2px);
      //     }
      //     .BrowseEventVenueDetailTicketBtnIcon {
      //       color: $brownDark;
      //       font-size: 18px;
      //     }

      //     .BrowseEventVenueDetailTicketBtnText {
      //       color: $brownDark;
      //       text-transform: uppercase;
      //       font-weight: 750;
      //     }
      //   }
      // }
    }

    .BrowseEventVenueDetailOraganizerSection {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: flex-start;
      margin-top: 50px;

      .BrowseEventVenueDetailOraganizerHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .BrowseEventVenueDetailOraganizerHeaderDetails {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      .card-banner {
        width: 100%;
      }
      .card-banner .overlay {
        background-color: rgba(0, 0, 0, 0.65);
        z-index: 10;
        padding: 1.25rem;
        color: #fff;
      }

      .card-banner .overlay.bottom {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
      }
      .img-100-100 {
        max-height: 100px;
        max-width: 100px;
      }
      .card-title {
        margin-bottom: 0.75rem;
        text-transform: capitalize;
      }
      .btn-dark {
        color: #fff;
        background-color: #020202;
        border-color: #000;
      }
      .btn-dark:hover {
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
          0 3px 6px rgba(0, 0, 0, 0.08);
      }
    }

    .BrowseEventVenueDetailOraganizerNewsletterSection {
      margin-top: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      border-radius: 4px;
      align-items: center;
      background-color: $brownDark;
      padding-bottom: 20px;

      .BrowseEventVenueDetailOraganizerNewsletterWrapper {
        display: flex;
        margin-top: 15px;
        flex-direction: column;
        width: 85%;
        justify-content: flex-start;

        .BrowseEventVenueDetailOraganizerNewsletterHeader {
          display: flex;
          align-items: flex-start;
          gap: 10px;

          .BrowseEventVenueDetailOraganizerNewsletterHeaderIconWrapper {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border-radius: 50%;

            .BrowseEventVenueDetailOraganizerNewsletterHeaderIcon {
              font-size: 24px;
            }
          }

          .BrowseEventVenueDetailOraganizerNewsletterHeaderText {
            color: $white;
            font-size: 18px;
          }
        }

        .BrowseEventVenueDetailOraganizerNewsletterEmailBox {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .BrowseEventVenueDetailOraganizerNewsletterEmailBoxEmail {
            width: 93.5%;
            padding: 10px 8px;
            border: none;
            outline: none;
          }

          .BrowseEventVenueDetailOraganizerNewsletterEmailBtn {
            width: 100%;
            padding: 10px;
            border: none;
            background-color: $darkBrownSecondary;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .BrowseEventSidebarSection {
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px;

    .BrowseEventsSidebarContainer {
      width: 100%;
    }
  }
}

.Model {
  width: 800px !important;
  .ModelBox {
    .ModelHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid $lightGray;
      padding-bottom: 15px;

      .ModelHeaderIcon {
        font-size: 23px;
      }

      .ModelBoxBuyTicketText {
        font-size: 20px;
      }
    }

    .ModelContent {
      margin-top: 10px;

      .ModelTitle {
        font-weight: 700;
        font-weight: 600;
        font-family: $open-sans;
      }

      .ModelDetailsWrapper {
        display: flex;
        margin-top: 5px;
        flex-direction: column;
        .ModelVenue {
          display: flex;
          align-items: baseline;
          gap: 5px;
          .ModelIcon {
            color: $secondaryDark;
          }

          .ModelAddress {
            font-family: $open-sans;
            font-size: 12px;
          }
        }
      }
    }

    .ModelBoxTicketPurchaseBox {
      display: flex;
      margin-top: 30px;
      // align-items: center;
      justify-content: space-between;
      background-color: $border;
      padding: 30px 10px;

      .ModelBoxTicketPurchaseTitleBox {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        .ModelBoxTicketPurchaseTitleCartBox {
          display: flex;
          justify-items: center;
          gap: 8px;

          .ModelBoxTicketPurchaseTitleIcon {
            font-size: 18px;
          }
        }

        .ModelBoxTicketPurchasePrice {
          font-weight: 700;
          font-family: $open-sans;
        }
      }

      .ModelBoxTicketPurchaseCounterBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 6px;
        width: 100%;

        .ModelBoxTicketPurchaseCounter {
          background-color: $lightGray;
          width: 350px;
          border: none;
          border-radius: 0px !important;
        }

        .ModelBoxTicketPurchaseCounterTicketsRemaining {
          background-color: $price;
          padding: 2px 5px;
          font-size: 12px;
          color: $white;
        }
      }
      input:focus {
        color: #495057;
        background-color: #fff;
        border-color: #565660;
        outline: 0;
        box-shadow: none;
      }
    }
  }
}

.Event.Model {
  width: 80% !important;
  overflow: auto;
  height: 700px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-modal-root
  .ant-modal-centered
  .ant-modal {
  top: 20px;
}

.ant-modal-footer {
  display: none;
}
.ant-input-number:where(.css-dev-only-do-not-override-amq5gd).ant-input-number
  .ant-input-number-handler-wrap {
  background: $brownDark;
  color: $white;
}

.ant-input-number:where(.css-dev-only-do-not-override-amq5gd).ant-input-number
  .ant-input-number-handler-up-inner,
:where(.css-dev-only-do-not-override-amq5gd).ant-input-number
  .ant-input-number-handler-down-inner {
  color: #ffffff;
  font-size: 12px !important;
}

// .ant-btn:where(.css-dev-only-do-not-override-amq5gd).ant-btn-primary {
//   color: #fff;
//   background-color: red !important;
//   box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
// }

// .ant-btn:where(.css-dev-only-do-not-override-amq5gd)   {
//   background-color: black;
//   border-color: #d9d9d9;
//   color: rgba(0, 0, 0, 0.88);
//   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
// }

@media screen and (max-width: 610px) {
  .ModelBoxTicketPurchaseCounter {
    background-color: $lightGray;
    width: 200px !important;
    border: none;
    border-radius: 0px !important;
  }
}

@media screen and (max-width: 463px) {
  .ModelBoxTicketPurchaseBox {
    flex-direction: column;
    gap: 15px;
  }

  .ModelBoxTicketPurchaseTitleBox {
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;

    .ModelBoxTicketPurchaseTitleCartBox {
      display: flex !important;
    }
  }

  .ModelBoxTicketPurchaseCounterBox {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
  }
}

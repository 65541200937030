@import "../../../../../assets/theme/variable";
@import "../../../../../assets/theme/fonts";

.PayoutMethodCard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 40px;
  gap: 280px;
  //   background-color: blanchedalmond;

  .PayoutMethodContainer {
    display: flex;
    align-items: center;
    gap: 230px;
  }

  .PayoutMethodIconBox {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 5px;
    border: none;
    outline: none;
    background-color: $brownDark;
    border-radius: 30px;

    .PayoutMethodIcon {
      color: $white;
      font-size: 18px;
    }

    .PayoutMethodIconText {
      color: $white;
      font-size: 18px;
      font-weight: 600;
      font-family: $mont;
    }
  }
}

@media screen and (max-width: 1400px) {
  .PayoutMethodCard {
    gap: 145px;

    .PayoutMethodContainer {
      gap: 150px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .PayoutMethodCard {
    gap: 100px;

    .PayoutMethodContainer {
      gap: 100px;
    }
  }
}

@media screen and (max-width: 768px) {
  .PayoutMethodCard {
    justify-content: center;
    gap: 50px !important;
    padding: 10px !important;
  }

  .PayoutMethodContainer {
    gap: 20px !important;
  }
}

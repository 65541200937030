@import "../../../../../../assets/theme/variable";
@import "../../../../../../assets/theme/fonts";

.OrganizerProfileSection {
  padding: 20px 30px;
  margin-top:  0px;
  border: none;
  .bg-white{
    background-color: #f1f3f7 !important;
    &:focus{
      background-color: white !important;
    }
  }
}

.ProfileSettingOptionsContainer {

  .ProfileSettingOptionsBox {
  
    .ProfileSettingRadioBtn{
      font-weight: 600;
    }
  .ProfleSettingOptionDescBox {
    margin-top: .25rem;
    margin-bottom: 10px;
    
  
    .ProfleSettingOptionDescIcon {
      font-size: 10px;
    }
  
    .ProfleSettingOptionDesc {
     
      font-size: 11px;
      color: #6c757d!important;
    }
  }

    
  }
}

.ProfleSettingOptionDescBox {
  margin-top: .25rem;
  margin-bottom: 10px;

  .ProfleSettingOptionDescIcon {
    font-size: 10px;
    color: #1a1a1d;
  }

  .ProfleSettingOptionDesc {
   
    font-size: 11px;
    color: #6c757d!important;
  }
}
.paymencard{
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f1f3f7;
    border-bottom: 1px solid #dae2e6;
}
}
.nav-pills .nav-link {
  color: #1a1a1d;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #1a1a1d;
}

.img-sm {
  width: 80px;
  height: 80px;
}
@import "../../../../assets/theme/variable";

// .Sidebar {
//   width: auto !important;
//   height: auto !important;
//   // overflow: auto;
// }

.ant-menu
  :where(.css-dev-only-do-not-override-pr0fja).ant-menu-light
  .ant-menu-item-selected,
.ant-menu-item-selected {
  background-color: $border !important;
}

.ant-menu
  :where(.css-dev-only-do-not-override-pr0fja).ant-menu-light
  .ant-menu-item-selected,
.ant-menu-item-selected {
  color: $brownDark !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
// 
.attendeeSidebar {
  padding: 32px 0;
}
.attendeeSidebar a div{
  display: flex;
  align-items: center;
}
.attendeeSidebar a div spna {
  display: flex;
  margin-right: 5px;
}



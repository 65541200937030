.EventsDetailSection {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .EventDetailsContainer {
    display: flex;
    width: 80%;
    margin-top: 50px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .EventDetailsContainer {
    width: 90% !important;
  }
}

@media screen and (max-width: 770px) {
  .EventDetailsContainer {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
}

.setting .form-label {
  font-weight: 600;
}
.setting .form-label span {
  color: red;
}
.card.setting {
  padding: 1rem 1.2rem;
  display: block;
  background: #fff;
  border-radius: 0.2rem;
}
.setting .badge {
  padding: 0.5em;
  font-size: 85%;
  font-weight: 400;
  border-radius: 0;
}
.setting .badge-info {
  color: #fff;
  background-color: #31708f;
}

.header > .navbar {
  /* border-bottom: 1px solid #f1f3f7; */
  background-color: white !important;
  padding: 0 1rem;
}
.nav-link {
  /* display: block; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .dropdown-item {
  color: #444;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #000;
}
.dropdown .nav-link {
  flex-direction: row;
  gap: 3px;
}
.dropdown .dropdown-item {
  padding: 8px 0 8px 20px;
}
.dropdown .dropdownMenu {
  position: absolute;
  transform: translate(35px, 46px);
  top: 0;
  left: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 40, 100, 0.12);
  background-color: white;
  z-index: 999;
  width: 15rem;
  opacity: 0;
  visibility: hidden;
  /* transition: all 0.5s linear; */
  height: 400px;
  overflow-y: scroll;
}
.dropdown .dropdownMenu > ul {
  padding: 10px 0;
}
.dropdown .dropdownMenu > ul > li {
  text-align: left;
  padding: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
.dropdown .dropdownMenu svg {
  font-size: 16px;
  margin-right: 5px;
}
.dropdown > .nav-link:hover + .dropdownMenu,
.dropdown .dropdownMenu:hover {
  opacity: 1;
  visibility: visible;
}
.dropdown-item:hover {
  color: #000;
  background-color: #f1f3f7;
}
.navbar .navbar-nav .nav-item .nav-link.active {
  background: #1a1a1d;
  color: #fff;
}

@media (min-width: 1301px) {
  .navbar-expand-lg .navbar-nav .nav-item .nav-link {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.topSection {
  padding: 0.5rem 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 1021 !important;
  background-color: white;
}
.header-logo .brand-wrap .logo {
  margin-right: 7px;
  max-height: 42px;
  display: inline-block;
}
.SearchBox {
  position: relative;
  display: flex;
  align-items: center;
}
.SearchBox .form-control {
  border: 1px solid #f1f3f7;
  background-color: #f1f3f7;
  border-radius: 0.25rem;
  font-size: 0.805rem;
}
.SearchBox .form-control::placeholder {
  color: #8898aa;
}
.SearchBox .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #565660;
  box-shadow: none;
  outline: 0;
}
.searchIcon {
  position: absolute;
  top: auto;
  right: 7px;
  cursor: pointer;
  width: 24px;
  color: #888;
}
.header-action .widget-header {
  margin-left: 7px;
  margin-right: 7px;
  display: inline-block;
  position: relative;
}
.header-action .widget-header a,
.header-action .widget-header,
.header-action .widget-header .downIcon,
.header-action .widget-header .userIcon,
.header-action .widget-header .calendarIcon {
  color: #333;
  cursor: pointer;
}
.widget-header .icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  border-radius: 50px;
}
.widget-header .signup {
  position: relative;
}
.widget-header .img-thumbnail {
  padding: 1px;
  width: 35px;
  height: 35px;
  border-radius: 50px;
}

.widget-header .signUpDropDown {
  position: absolute;
  transform: translate(-98px, 21px);
  top: 0;
  left: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 40, 100, 0.12);
  background-color: white;
  z-index: 999;
  width: 10rem;
  opacity: 0;
  visibility: hidden;
}
.widget-header .signUpDropDown.profileLink {
  transform: translate(2px, 33px);
}
.widget-header .signUpDropDown > ul {
  padding: 10px 20px;
}
.widget-header .signUpDropDown > ul > li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
.widget-header .signUpDropDown .upIcon {
  position: absolute;
  top: -10px;
  right: 15px;
  font-size: 15px;
  /* color: rgba(0, 40, 100, 0.12); */
}
.widget-header .signup:hover + .signUpDropDown,
.widget-header .signUpDropDown:hover {
  opacity: 1;
  visibility: visible;
}
.togglebtn {
  font-size: 31px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-drawer {
  z-index: 999999 !important;
}
.offcanvas {
  max-width: 230px !important;
  /* background-color: black !important; */
  /* color: white !important; */
}
.btn-close:focus {
  box-shadow: none !important;
}

.dropdown-cart {
  position: relative;
  display: inline-flex;
  gap: 7px;
  width: 100%;
}
.dropdown-divider-cart {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.badge-notification[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -11px;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 50%;
  background: #31708f;
  color: #fff;
}
.badge-notification-left.badge-notification[data-badge]:after {
  left: -10px;
}
.badge-notification-right.badge-notification[data-badge]:after {
  right: -5px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-dropdown {
  max-width: 287px;
  max-height: 400px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .widget-header .signUpDropDown {
    transform: translate(-113px, 35px) !important;
  }
}

@media (max-width: 320px) {
  .widget-header .signUpDropDown {
    transform: translate(-113px, 35px) !important;
  }
}

.MyScanners .dropdown-menu {
  min-width: 17rem;
  padding: 10px 15px;
}
.MyScanners .form-label {
  font-weight: 600;
}
.MyScanners .enabled-menu {
  min-width: 25rem;
}

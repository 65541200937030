@import "../../../assets/theme/variable";

.VenueSection{
    width: 100%;
    padding-bottom: 60px;
    background-color: $border;
    display: flex;
    flex-direction: column;
    align-items: center;

    // .VenueSearchContainer{
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: space-between;
    //     width: 80%;
    //     margin-top: 50px;

    //     // .VenueBox{
    //     //     display: flex;
    //     //     flex-direction: column;
    //     //     align-items: flex-start;
    //     //     gap: 6px;

    //     //     .Venue{
    //     //         font-size: 14px;
    //     //     }

    //     //     .VenueSelect{
    //     //         width: 350px;
    //     //         height: 40px;
    //     //         border: none !important;
    //     //     }

    //     //     .VenueSelect:where(.css-dev-only-do-not-override-pr0fja).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //     //         border: none;
    //     //     }
    //     // }

    // }

  
}


// @media screen and (max-width: 895px) {
//     .VenueBox{
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         gap: 6px;


//         .VenueSelect{
//             width: 300px !important;
//             height: 40px;
//         }

//     }
//   }


//   @media screen and (max-width: 768px) {
//     .VenueBox{
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         gap: 6px;


//         .VenueSelect{
//             width: 250px !important;
//         }

//     }
//   }

//   @media screen and (max-width: 671px) {
//     .VenueBox{
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         gap: 6px;


//         .VenueSelect{
//             width: 230px !important;
//         }

//     }
//   }


//   @media screen and (max-width: 605px) {

//     .VenueSearchContainer{
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: center !important;
//         width: 80%;
//         margin-top: 50px;
//     .VenueBox{
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         gap: 10px !important;


//         .VenueSelect{
//             width: 230px !important;
//         }

//     }
// }
//   }